import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Nav({ setMobileToggle }) {
  return (
    <ul className="cs_nav_list fw-medium text-uppercase" id="menue-bar">
      <li>
        <NavLink to="/" onClick={() => setMobileToggle(false)}>
          Home
        </NavLink>
      </li>
      <li>
        <NavLink to="/about-us" onClick={() => setMobileToggle(false)}>
          About Us
        </NavLink>
      </li>
      <li>
        <NavLink to="/contact-us" onClick={() => setMobileToggle(false)}>
          Contact us
        </NavLink>
      </li>
    </ul>
  );
}
