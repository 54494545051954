import React from 'react'
import Button from '../Button';

export default function NotFoundPage() {
    return (
        <div className="not-found-page">
            <div className="content">
                <img src="images/404.svg" className="not-found-img" alt="not-found"/>
                <h3 className="cs_mb_5">Page Not Found</h3>
                <p className="cs_mb_0 error_para">we're sorry the page you requested could not be found</p>
                <p className="error_para">Please go back to the home page</p>
                <Button btnText="Back to Home" btnUrl="/" />
            </div>
        </div>
    )
}
