import React, { useRef, useState, useEffect } from 'react'
import emailjs from '@emailjs/browser'
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from 'react-hook-form'
import { contactSchema } from '../Form/ValidationSchema';
import ReCAPTCH from 'react-google-recaptcha';

export default function ContactForm({ variant }) {
  const form = useRef();
  const recaptcha = useRef(null);

  const [toastData, setToastData] = useState(null);
  const [show, setShow] = useState(true);
  const [isCaptchaErr, setIsCaptchaErr] = useState(false);
  const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: zodResolver(contactSchema) });

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 3000);

    // Cleanup the timer if the component is unmounted before 5 seconds
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isCaptchaErr) return;

    reset();

    setTimeout(() => {
      setShow(false);
      setToastData({
        varient: '',
        body: ''
      })
    }, 3000);

  }, [show, reset, isCaptchaErr]);


  const sendEmail = (e) => {
    setShow(false);
    const captchaValue = recaptcha.current.getValue();

    if (!captchaValue) {
      setIsCaptchaErr(true)
      setShow(true)
      setToastData({
        varient: 'danger',
        subject: 'Failed to send message!',
        body: 'Please verify the reCAPTCHA.'
      })
      return;
    }
    setIsCaptchaErr(false);

    emailjs
      .sendForm(process.env.REACT_APP_SERVIVE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      }).then(() => {
        setShow(true)
        setToastData({
          varient: 'success',
          subject: 'Message sent successfully!',
          body: 'Thank you for reaching out to us, we will contact you shortly. '
        })
      }, (error) => {
        setShow(true)
        setToastData({
          varient: 'danger',
          subject: 'Failed to send message!',
          body: 'Sending message failed, Please try again. '
        })
      });

  };


  return (
    <form
      ref={form}
      onSubmit={handleSubmit(sendEmail)} noValidate
      className={`cs_contact_form bg-white cs_pt_64 cs_pl_80 cs_pr_80 cs_pb_80 cs_pl_lg_30 cs_pr_lg_30 position-relative cs_rounded_20 ${variant ? variant : ''
        }`}
      style={{ backgroundImage: `url('/images/contact_bg_pattern.svg')` }}
    >
      <div className="cs_section_heading cs_style_1 d-flex align-items-center text-center cs_mb_30">
        <div className="cs_section_heading_in">
          <h2 className="cs_fs_48 cs_fs_lg_36 m-0">Contact Us</h2>
        </div>
      </div>
      <div className="cs_mb_15">
        <input
          className="form-control cs_fs_16 cs_rounded_5 border-0 bg-gray"
          type="text"
          placeholder="Your Name"
          name="user_name"
          id="contactForm.user_name"
          {...register("user_name")}
        />
        {errors.user_name && errors.user_name.message && <p className="error_txt cs_mb_0">{errors.user_name.message}</p>}
      </div>

      <div className="cs_mb_15">
        <input
          className="form-control cs_fs_16 cs_rounded_5 border-0 bg-gray"
          type="email"
          placeholder="Your Email"
          name="user_email"
          id="contactForm.user_email"
          {...register("user_email")}
        />
        {errors.user_email && errors.user_email.message && <p className="error_txt cs_mb_0">{errors.user_email.message}</p>}
      </div>

      <div className="cs_mb_15">
        <input
          className="form-control cs_fs_16 cs_rounded_5 border-0 bg-gray"
          type="text"
          placeholder="Subject"
          name="subject"
          id="contactForm.subject"
          {...register("subject")}
        />
        {errors.subject && errors.subject.message && <p className="error_txt cs_mb_0">{errors.subject.message}</p>}
      </div>
      <div className="cs_mb_15">
        <textarea
          className="form-control cs_fs_16 cs_rounded_5 border-0 bg-gray"
          placeholder="Message here ..."
          cols={30}
          rows={4}
          name="message_note"
          defaultValue={''}
          id="contactForm.message_note"
          {...register("message_note")}
        />
        {errors.message_note && errors.message_note.message && <p className="error_txt cs_mb_0">{errors.message_note.message}</p>}
      </div>
      {ReCAPTCH && <div className="cs_mb_30">
        <ReCAPTCH sitekey={process.env.REACT_APP_SITE_KEY} ref={recaptcha} />
      </div>}
      <button className="cs_btn cs_style_1 cs_fs_16 cs_rounded_5 cs_pl_30 cs_pr_30 cs_pt_10 cs_pb_10 overflow-hidden">
        <span>Submit Now</span>
      </button>

      {toastData != null && show && <div className='col-lg-12 py-3'>
        <p className={toastData.varient === 'success' ? 'success_txt' : 'error_txt'}>{toastData.body}</p>
      </div>}
    </form>
  );
}
