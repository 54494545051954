import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home2 from './components/Pages/Home2';
import AboutPage from './components/Pages/AboutPage';
import ContactPage from './components/Pages/ContactPage';
import NotFoundPage from './components/Pages/NotFoundPage';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home2 />} />
        <Route path="/about-us" element={<AboutPage />} />                        
        <Route path="/contact-us" element={<ContactPage />} />
      </Route>
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
