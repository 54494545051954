import React from 'react';
import SectionHeadingStyle3 from '../../SectionHeading/SectionHeadingStyle3';
import IconboxStyle3 from '../../Iconbox/IconboxStyle3';

export default function AboutSectionStyle2({
  imgUrl,
  bgImgUrl,
  workExprience,
  workExprienceTitle,
  sectionTitleUp,
  sectionTitle,
  sectionSubTitle,
  featureList,
}) {
  return (
    <div className="container">
      <div className="row align-items-center">
        {/* <div className="col-xl-7">
          <img src={imgUrl} alt="about" />
        </div> */}
        <div className="col-xl-12">
          <div className="cs_about-1-spacing">
            <div className="cs_about cs_style_1">
              <SectionHeadingStyle3
                sectionTitleUp={sectionTitleUp}
                sectionTitle={sectionTitle}
                sectionSubTitle={sectionSubTitle}
                className="cs_mb_25"
              />
              {featureList?.map((item, index) => (
                <IconboxStyle3 key={index} {...item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
