import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Breadcrumb({ bgUrl, title }) {
  const [urlSegments, setUrlSegments] = useState([]);
  useEffect(() => {
    const pathSegments = window.location.pathname
      .split('/')
      .filter(segment => segment !== '');
    setUrlSegments(pathSegments);

  }, []);
  return (
    <section
      className="cs_page_header position-relative background-filled d-flex align-items-center justify-content-between"
      style={{ backgroundImage: `url(${bgUrl})` }}
    >
      <div className="container position-relative z-index-1">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb cs_fs_18 cs_mb_5">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            {urlSegments.map((segment, index) => (
              <li key={index} className="breadcrumb-item text-capitalize">
                {index < urlSegments.length - 1 ? (
                  <Link to={`/${urlSegments.slice(0, index + 1).join('/')}`}>
                    {segment}
                  </Link>
                ) : (
                  <span>{segment.replace("-"," ")}</span>
                )}
              </li>
            ))}
          </ol>
        </nav>
        <h1 className="cs_fs_48 cs_fs_lg_36 text-white m-0">{title}</h1>
      </div>
    </section>
  );
}
