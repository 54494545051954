import React from 'react';

export default function IconboxStyle3({ imgUrl, title, desc, iconList }) {
  return (
    <div className="d-flex align-items-center cs_mb_50">
      {imgUrl !== '' && <div className="d-flex align-items-center justify-content-center cs_height_90 cs_width_90 flex-none cs_mr_20 bg-gray rounded-circle">
        <img src={imgUrl} alt="iconbox-img" />
      </div>}
      <div>
        <h2 className="cs_fs_26 cs_mb_10 d-flex justify-content-center text-center">{title}</h2>
        <p className="m-0 justified-para">{desc}</p>
        <div className="row cs_mt_20">
          <div className="d-flex flex-wrap align-items-center justify-content-center">
            {iconList?.map((item, index) => (
              <div className="icon-container" key={index}>
                <div className="icon-circle" style={{background:`${item.iconColour}`}} key={index}>
                  <img src={item.iconUrl} className="svg_class" alt="iconbox-img" />
                </div>
                <h6 className="icon-text">{item.iconText}</h6>
              </div>
              // <IconboxStyle3 key={index} {...item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
