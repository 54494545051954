import Button from '../Button';
import parse from 'html-react-parser';

export default function HeroStyle2({ titleUp, title, btnText, btnUrl, bgUrl }) {
  return (

    <section
      className="cs_hero cs_style_2 d-flex align-items-center justify-content-center background-filled position-relative overflow-hidden"
      style={{ backgroundImage: `url(${bgUrl})` }}
    >
      <>
        <div className="container">
          <div className="cs_hero_text position-relative cs_zindex_3 text-center">
            <h2 className="text-white cs_mb_15 fw-normal cs_fs_18">
              {titleUp}
            </h2>
            <h1 className="text-white cs_mb_45 cs_fs_60 cs_fs_lg_46 text-uppercase">
              {parse(title)}
            </h1>
            <Button btnText={btnText} btnUrl={btnUrl} />
          </div>
        </div>
        <div className="bg-primary opacity-75 position-absolute w-100 h-100 start-0 top-0" />
      </>
    </section>
  );
}
