import React, { useEffect } from 'react';
import Breadcrumb from '../Breadcrumb';
import Section from '../Section';
import FormStyle2 from '../Form/FormStyle2';
import IconboxStyle2 from '../Iconbox/IconboxStyle2';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import { useLocation } from 'react-router-dom';

export default function ContactPage() {
  pageTitle('Contact Us');

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));

      if (element) {
        const yOffset = -80; // Adjust this value based on your header height
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);


  return (
    <>
      <Breadcrumb title="Contact Us" bgUrl="/images/page_header1.png" />
      <Section pt="133" ptLg="75" pb="133" pbLg="75" id="contact-form">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-6">
              <SectionHeadingStyle3
                sectionTitleUp="Have any question?"
                sectionTitle="Write a Message"
              />

              <FormStyle2 />

            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="cs_pl_60 cs_pl_lg_0 cs_pt_lg_60">
                <SectionHeadingStyle3
                  sectionTitleUp="Contact Us"
                  sectionTitle="Let’s work together"
                  sectionSubTitle="We’re excited to help bring your dreams to life. Please reach out with any messages or questions you may have."
                  className="cs_mb_40"
                />
                <div className="cs_iconbox_wrapper cs_style1">
                  <IconboxStyle2
                    imgUrl="/images/icons/contact_icon_2.svg"
                    title="info@notionblend.com"
                    titleUp="Send an Email"
                  />
                  <IconboxStyle2
                    imgUrl="/images/icons/contact_icon_3.svg"
                    title="68 Circular Road, #02-01, 049422, Singapore"
                    titleUp="Address"
                  />
                  <IconboxStyle2
                    imgUrl="/images/icons/contact_icon_3.svg"
                    title="Colombo, Sri Lanka"
                    titleUp="Development Hub"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}
