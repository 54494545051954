import React, { useEffect } from 'react';
import Breadcrumb from '../Breadcrumb';
import Section from '../Section';
import AboutUsSection from '../Section/AboutSection';
import { pageTitle } from '../../helpers/PageTitle';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { Link } from 'react-router-dom';

const brandData = [
  { imgUrl: '/images/brand4.png' },
  { imgUrl: '/images/brand5.png' },
];

export default function AboutPage() {
  pageTitle('About Us');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Breadcrumb title="About Us" bgUrl="/images/page_header2.png" />

      <Section
        pt="135"
        ptLg="75"
        pb="140"
        pbLg="80"
        className="position-relative"
      >
        <AboutUsSection
          thumbUrl="/images/experience_img1.png"
          experienceBgUrl="/images/experience_bg1.png"
          experienceIconUrl=""
          experienceNumber=""
          experienceTitle=""
          sectionTitleUp="Our Journey"
          sectionTitle='Get Ready for Success with <span className="text-accent">Us</span> Your Path to Growth.'
          sectionSubTitle="NotionBlend was founded with a vision to deliver innovative software solutions tailored to meet our clients’ needs. We are dedicated to crafting customized solutions across diverse businesses.
          <br/><br/>
          Headquartered in Singapore, with our primary development hub in Sri Lanka, we are a pioneering startup committed to providing cutting-edge digital solutions to clients worldwide.
          <br/><br/>
          Our team comprises of skilled professionals proficient in building Web applications, Mobile applications, as well as developing Machine Learning models. Additionally, we offer comprehensive services in UI/UX, Quality Assurance, and DevOps."
          grothPercentage="90"
          featureList={[
            {
              title: 'Accounting and Bookkeeping',
              subTitle:
                'Services related to financial record-keeping, bookkeeping.',
            },
            {
              title: 'Human Resources (HR) Consulting',
              subTitle: 'Assistance with HR-related tasks such as recruitment.',
            },
          ]}
          btnText="Get A Quote"
          btnUrl="/contact-us"
          videoBtnText="Watch the video"
          videoBtnUrl="https://www.youtube.com/embed/0Tz4Ycjbdbg"
        />
      </Section>

      <Section pt="140" ptLg="80" pb="130" pbLg="70" className="bg-grey">
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className="cs_about cs_style_1">
                <SectionHeadingStyle3
                  sectionTitleUp='Our Speciality'
                  sectionTitle='Focused Solutions for <span className="text-accent">Telco</span> Industry'
                  sectionSubTitle="<p className=cs_mb_20>Currently, NotionBlend is actively developing solutions tailored to meet the unique demands of the UK, European, and African markets:</p>
          <h2 className=cs_fs_20 cs_mb_10>eSIM Acquisition</h2>
          <p className=cs_mb_20> With NotionBlend, acquiring eSIMs has never been easier. Our platform provides a streamlined process for purchasing and managing eSIMs, offering users flexible and convenient mobile connectivity without the need for physical SIM cards. This digital solution supports a wide range of devices and networks, enabling users to switch carriers and manage their mobile plans with ease.</p><h2 className=cs_fs_20 cs_mb_10>Global Mobile Recharge Accessibility</h2>
          <p className=cs_mb_20> Our platform offers seamless mobile recharge services worldwide, ensuring users can maintain connectivity regardless of their location. Our solution provides instant access to mobile top-ups, helping users stay connected with minimal effort. Our global network supports various operators and currencies, making international recharges straightforward and efficient.</p>
          <h2 className=cs_fs_20 cs_mb_10>International Gift Card Purchases and Activation</h2>
          <p className=cs_mb_20> We focus on simplifing the process of purchasing and activating gift cards across borders. Our platform allows users to easily buy and send gift cards from a wide range of retailers, ensuring a smooth and enjoyable gifting experience. With secure transactions and instant delivery, our solution facilitates effortless cross-border purchases and enhances the global gifting process, making it more accessible and convenient.</p>
          <h2 className=cs_fs_20 cs_mb_10>Strategic Approach</h2>
          <p>In addition to our business-to-consumer (B2C) offerings, NotionBlend is dedicated to serving the unique needs of business clients through our strategic partnerships with distributors. Our B2B solutions include tailored services to meet specific business requirements. Our comprehensive approach ensures efficient distribution, accurate tracking, and effective management of eSIMs, enhancing operational efficiency and providing our partners with a competitive edge in the market.</p>"
                  className={'cs_mb_15'}
                />
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section pt="80" pb="80" ptLg="80">
        <div className='row'>
          <div className='col-lg-12'>
            <div className="cs_about cs_style_1">
              <div className='cs_section_heading cs_style_1 d-flex align-items-center mb-5'>
                <div className="cs_section_heading_in text_center">
                  <h2 className='cs_fs_48 cs_fs_lg_36 cs_mb_10 text-center'> Our Clients </h2>
                </div>
              </div>
              {/* <BrandSection data={brandData} /> */}

            </div>
          </div>
        </div>
        <div className='row justify-content-center cs_mb_40'>
          <div className='d-flex align-items-center justify-content-center'>
            {brandData.map((item, index) => (
              <div key={index} className='d-flex align-items-center justify-content-center'>
                <img className="brand-img" src={item.imgUrl} alt="logo" />
              </div>
            ))}
          </div>
        </div>
      </Section>

      <Section
        style={{ backgroundImage: `url(${'/images/testimonial_bg.jpeg'})` }}
      >
        <div className='container cs_pt_40 cs_pb_40'>
          <div className='row'>
            <div className='col-lg-5'>
              <div className="cs_about cs_style_1">
                <SectionHeadingStyle3
                  sectionTitleUp=''
                  sectionTitle='<span className="text-accent">Future</span> Outlook'
                  sectionSubTitle="As we look ahead, NotionBlend remains committed to pushing boundaries and expanding our footprint in the telco industry. Inaddition, we are venturing into education, financial technology, and healthcare. By driving ongoing research and development, proactively engaging with clients, and maintaining our commitment to excellence, we are poised to lead the digital transformation across these diverse industries.
                  <br/><br/><p> Leveraging our dynamic skills and innovative solutions, we aim to create impactful e-learning tools, advanced fintech innovations, and cutting-edge healthcare technologies, all while upholding sustainability and social responsibility. NotionBlend is set to shape the future of these sectors worldwide.</p>"
                />
              </div>
            </div>
            <div className='col-lg-1'></div>
            <div className="col-lg-5 cs_mb_lg_55">
              <div className="cs_experience cs_style_1 position-relative d-flex jsutify-content-center align-items-center"
                style={{ height: '100%' }}>
                <div>
                  <img
                    src='/images/future_image.svg'
                    alt="Thumb"
                    className="position-relative cs_zindex_3 cs_rounded_15"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section pt="40" pb="40" ptLg="40" pbLg="40" mrLg='10' mlLg='10'> 
        <div className='container bg-cyan'>
          <div className='row'>
            <div className='col-lg-6 col-md-6'>
              <div className="cs_about cs_style_1 text-center d-flex align-items-center section-txt-alignment">
                <h3 className='text-whit mb-0'>Free consultation</h3>
              </div>
            </div>
            <div className='col-lg-6 col-md-6'>
              <div className="cs_about cs_style_1 text-center d-flex align-items-center section-btn-alignment">
                <Link
                  to='/contact-us#contact-form'
                  className="cs_btn cs_style_3 cs_fs_16 cs_rounded_5 cs_pl_30 cs_pr_30 cs_pt_10 cs_pb_10 overflow-hidden">
                  <span>Talk to us</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}
