import { useEffect } from 'react';
import HeroStyle2 from '../Hero/HeroStyle2';
import Section from '../Section';
import AboutSectionStyle2 from '../Section/AboutSection/AboutSectionStyle2.jsx';
import ContactSectionStyle2 from '../Section/ContactSection/ContactSectionStyle2.jsx';
import ServiceSection from '../Section/ServiceSection/index.jsx';
import WorkingProcessSection from '../Section/WorkingProcessSection.jsx';
import { pageTitle } from '../../helpers/PageTitle.js';

const serviceData = [
  {
    iconUrl: '/images/icons/service_icon1.svg',
    title: 'Web Application Development',
    subTitle:
      'We develop web solutions that are versatile and responsive using the latest technology for both front-end and back-end. Our design focuses on creating scalable architectures to ensure optimal performance and usability.',
    imgUrl: '/images/service1.png',
    href: '',
  },
  {
    iconUrl: '/images/icons/service_icon3.svg',
    title: 'Mobile App Development',
    subTitle:
      'We build high-quality mobile applications that operate seamlessly in both Android and iOS. Usability, performance, compatibility and providing the best experience to the user are guaranteed.',
    imgUrl: '/images/service2.png',
    href: '',
  },
  {
    iconUrl: '/images/icons/service_icon4.svg',
    title: 'Machine Learning & AI',
    subTitle:
      'We specialize in creating machine learning solutions for your needs. With rigorous research, we carefully design the models using cutting-edge technology. Our solutions are tailored to the client\'s needs.',
    imgUrl: '/images/service3.png',
    href: '',
  },
  {
    iconUrl: '/images/icons/service_icon2.svg',
    title: 'Software Consultation',
    subTitle:
      'We offer strategic advice and expert guidance to anyone who wishes to build software solutions. We analyze the problem/requirements and provide tailor made solutions, that are efficient and sustainable.',
    imgUrl: '/images/service4.png',
    href: '',
  }
];


const stepData = [
  {
    imgUrl: 'images/step2.png',
    title: 'Plan & Analysis',
    desc: 'The initial and most crucial stage of creating a software is to understand the client\'s needs. We meticulously spend time in ensuring that we have understood the client\'s goal.',
    step: '01',
  },
  {
    imgUrl: 'images/step1.png',
    title: 'Design & Develop',
    desc: 'The most important phase, where we transform the requirements in to an actual product. We dedicate significant time and effort to designing scalable architecture and developing solutions that adhere to best practices.',
    step: '02',
  },
  {
    imgUrl: 'images/step3.png',
    title: 'Release & Support',
    desc: 'We deliver the developed product to our clients, ensuring it undergoes thorough testing at multiple levels before reaching the end-users. Optionally, we offer support services tailored to meet the specific needs of our clients. ',
    step: '03',
  },

];



export default function Home2() {
  pageTitle('NotionBlend');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeroStyle2
        titleUp="We are"
        title='Redefining <br />Software <span className="text-accent position-relative d-inline-block">Solutions</span>'
        btnText="Talk to Us"
        btnUrl="/contact-us#contact-form"
        bgUrl="/images/hero_slider1.png"
      />

      <Section pt="140" ptLg="80" className="bg-primary">
        <ServiceSection
          data={serviceData}
          sectionTitleDown="Digital solution services built just for <span className='text-accent'>YOU</span>"
          sectionBtnText=""
          sectionBtnUrl=""
          textVariant="text-white"
        />
      </Section>

      <Section pt="80" pb="80" pbLg="80" className="position-relative">
        <AboutSectionStyle2
          imgUrl="/images/about_img1.png"
          bgImgUrl="/images/experience_bg_2.jpeg"
          workExprience="40"
          workExprienceTitle="Work Experience"
          sectionTitleUp="About Us"
          sectionTitle='Your Trusted Technology
          <span className="text-accent">Partner</span>'
          sectionSubTitle="We excel in understanding the unique requirements of our clients by providing tailored solutions using cutting-edge technology along with our team. We ensure to help you achieve your business goals and objectives through our products."
          featureList={[
            {
              imgUrl: '',
              title: 'Solutioning',
              desc: 'We meticulously analyze your specific requirements to deliver tailored solutions that are not only the most suited for you but also designed with scalable architecture to grow alongside your business, ensuring longevity and flexibility. Using latest and cutting edge technology, we keep your business ahead of the curve, driving innovation at the core of our solutions.',
              iconList: [
                { iconUrl: "/images/icons/trust_icon1.svg", iconText: "Tailored Solutions", iconColour: "#0d6367" },
                { iconUrl: "/images/icons/trust_icon2.svg", iconText: "Scalable Architecture", iconColour: "#12757a" },
                { iconUrl: "/images/icons/trust_icon3.svg", iconText: "Innovation", iconColour: "#16878d" }],
            },
            {
              imgUrl: '',
              title: 'Quality Assurance',
              desc: 'We subject every deliverable to multiple rounds of rigorous testing to ensure the highest quality standards. By incorporating feedback and following industry best practices, we continuously enhance our products. Additionally, our QA processes guarantee compliance with industry regulations and robust security against potential threats.',
              iconList: [
                { iconUrl: "/images/icons/trust_icon4.svg", iconText: "Quality Assurance", iconColour: "#0d6367" },
                { iconUrl: "/images/icons/trust_icon5.svg", iconText: "Testing", iconColour: "#12757a" },
                { iconUrl: "/images/icons/trust_icon6.svg", iconText: "Compliance", iconColour: "#16878d" },
                { iconUrl: "/images/icons/trust_icon7.svg", iconText: "Security", iconColour: "#3b9398" }]
            },
            {
              imgUrl: '',
              title: 'Customer Service and Support',
              desc: 'We provide robust customer service with prompt assistance and issue resolution to ensure smooth operations, while every product comes with a warranty period, offering peace of mind to you and ensuring continued performance.',
              iconList: [
                { iconUrl: "/images/icons/trust_icon8.svg", iconText: "Customer service", iconColour: "#0d6367" },
                { iconUrl: "/images/icons/trust_icon9.svg", iconText: "Support", iconColour: "#12757a" },
                { iconUrl: "/images/icons/trust_icon10.svg", iconText: "Warranty", iconColour: "#16878d" }]
            },
            {
              imgUrl: '',
              title: 'User Experience & UI Designing',
              desc: 'We design user-friendly interfaces that enhance engagement and satisfaction. By prioritizing the needs and preferences of end-users we ensure that our solutions deliver the best possible experience.',
              iconList: [
                { iconUrl: "/images/icons/trust_icon11.svg", iconText: "User Interface", iconColour: "#0d6367" },
                { iconUrl: "/images/icons/trust_icon12.svg", iconText: "User Friendly", iconColour: "#12757a" },
                { iconUrl: "/images/icons/trust_icon13.svg", iconText: "User-centric", iconColour: "#16878d" },
                { iconUrl: "/images/icons/trust_icon14.svg", iconText: "Engagement", iconColour: "#3b9398" }]
            },
            {
              imgUrl: '',
              title: 'Strategic Consultation',
              desc: 'We collaborate closely with you to analyze your business needs, identifying opportunities for growth and improvement. By developing a technology roadmap aligned with your long-term objectives and leveraging our deep industry expertise, we provide strategic insights that drive success.',
              iconList: [
                { iconUrl: "/images/icons/trust_icon15.svg", iconText: "Business Analysis", iconColour: "#0d6367" },
                { iconUrl: "/images/icons/trust_icon16.svg", iconText: "Technology Roadmapping", iconColour: "#12757a" },
                { iconUrl: "/images/icons/trust_icon17.svg", iconText: "Industry Expertise", iconColour: "#16878d" }]
            }
          ]}
        />
      </Section>

      <Section
        pt="100"
        ptLg="75"
        pb="85"
        pbLg="55"
        style={{ backgroundImage: `url('/images/contactBG.png')` }}
      >
        <ContactSectionStyle2
          sectionTitle="Let’s Work Together"
          sectionTitleUp="Contact Us"
          sectionSubTitle="We are ready to give life to your dreams. Please feel free to get in touch with us for a free consultation."
          featureList={[
            'Fulfilling all your requirements',
            'Providing customized quotations',
            'Providing complete transparency',
            'Creating tailor made software solutions'
          ]}
          contactInfo={[
            {
              imgUrl: '/images/icons/contact_icon_2.svg',
              titleUp: 'Send an Email',
              title: 'info@notionblend.com',
              textColor: 'text-primary',
            },
          ]}
        />
      </Section>


      {/* <Section style={{ backgroundImage: 'url("/images/staps_bg.jpeg")' }}> */}
      <Section style={{ backgroundImage: 'url("/images/stepsBG.jpg")' }} pt="80" ptLg="75">
        <div className="cs_pb_131">
          <WorkingProcessSection
            sectionTitleUp="How we do"
            sectionTitle="We follow a systematic approach to deliver<br/>  your products"
            data={stepData}
          />
        </div>
      </Section>
    </>
  );
}
