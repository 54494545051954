import React from 'react';

export default function Footer() {
  return (
    <footer className="cs_footer background-filled text-white">
      <div className="cs_copyright text-center cs_fs_124 cs_lh_lg cs_pt_36 cs_pb_36">
        <div className='container'>
          <p className='mb-3'> Registration Number (UEN) - 202427259E</p>
        </div>
        <div className="container">
          <p className="m-0">
            © {new Date().getFullYear()} <span className="brand-name">NotionBlend</span> (Pte) Ltd.
            All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
